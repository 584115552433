import React from "react";
import Box from "@material-ui/core/Box";

export default function DemoMultiviewer() {
  return (
    <div style={{ minHeight: "60vh" }}>
      <Box
        display="flex"
        justifyContent="center"
        style={{
          margin: "auto",
          resize: "both",
          overflow: "auto",
          padding: "15px",
          width: "960px",
          minHeight: "540px",
        }}
      >
        <video
          style={{ opacity: "100%" }}
          width="100%"
          height="100%"
          preload="auto"
          autoPlay
          muted
          loop
        >
          <source
            src="https://hbmc-director.s3.amazonaws.com/tmp/mvstream.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Box>
    </div>
  );
}
