import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Card, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import { UserContext } from "../contexts/UserProvider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Multiviewer from "./Multiviewer";
import { fonts, colors } from "./Theme";
import DemoMultiviewer from "./DemoMultiviewer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MultiviewTabs(props) {
  const [value, setValue] = React.useState(0);
  const [state, dispatch] = React.useContext(UserContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTab = withStyles((theme) => ({}))((props) => (
    <Tab
      style={{
        fontFamily: fonts.medium,
        color: state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1,
        fontSize: "14px",
        marginLeft: "25px",
        marginRight: "25px",
      }}
      {...props}
    />
  ));

  return (
    <Card style={{ opacity: "100%" }}>
      <Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
            paddingTop: 1,
          }}
        >
          <Tabs
            TabIndicatorProps={{
              style: {
                background: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="tabs"
          >
            {state.distributions.map((distribution, index) => {
              return <StyledTab label={distribution} {...a11yProps(index)} />;
            })}
          </Tabs>
        </Box>
        {state.distributions.map((distribution, index) => {
          return (
            <TabPanel value={value} index={index}>
              {state.routingManagerTenantId == "DEMO" ? (
                <DemoMultiviewer></DemoMultiviewer>
              ) : (
                <Multiviewer
                  feedID={distribution}
                  style={{ opacity: "100%" }}
                ></Multiviewer>
              )}
            </TabPanel>
          );
        })}
      </Box>
    </Card>
  );
}
